"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "TextImageOverlay", {
  enumerable: true,
  get: function get() {
    return _TextImageOverlay.TextImageOverlay;
  }
});
var _TextImageOverlay = require("./TextImageOverlay");