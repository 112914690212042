"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "component", {
  enumerable: true,
  get: function get() {
    return _AstonTextContent["default"];
  }
});
exports.name = exports.id = void 0;
var _AstonTextContent = _interopRequireDefault(require("./AstonTextContent"));
var name = exports.name = 'AstonTextContent';
var id = exports.id = 'AstonTextContent';