"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "FiftyFifty", {
  enumerable: true,
  get: function get() {
    return _FiftyFifty.FiftyFifty;
  }
});
var _FiftyFifty = require("./FiftyFifty");