"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "TextContent", {
  enumerable: true,
  get: function get() {
    return _TextContent.TextContent;
  }
});
var _TextContent = require("./TextContent");